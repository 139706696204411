import {PlatformLoader} from "@flexinet/ui-components";
import {PortalDashboard} from "../components/PortalDashboard";
import {useAuth} from "../../../hooks/useAuth";

export const IndexPage = () => {
    const {user} = useAuth();

    if (!user) {
        return <PlatformLoader message="Loading dashboard...Please wait!"/>;
    }

    return <PortalDashboard/>;
};
