export const actions = [
    'GetDashboard',
    'GetUserProfile',
    'GetSupport',
    'GetPlatformHealth',

    'ListBots',
    'GetBot',
    'CreateBot',
    'UpdateBot',
    'DeleteBot',

    'ListServicePlans',
    'GetServicePlan',
    'CreateServicePlan',
    'UpdateServicePlan',
    'DeleteServicePlan',


];

export const actionConstants = actions.reduce((acc, action) => {
    acc[action.replace(/[A-Z]/g, (match, offset) => (offset > 0 ? '_' : '') + match.toLowerCase())
              .toUpperCase()] = action;
    return acc;
}, {} as Record<string, string>);