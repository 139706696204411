import {useContext} from 'react';
import {VerifiedPermissionsContext} from '../context/VerifiedPermissionsContext.tsx';
import config from '../config';
import {
    BatchIsAuthorizedWithTokenCommandInput,
    BatchIsAuthorizedWithTokenInputItem,
    IsAuthorizedWithTokenCommandInput,
} from '@aws-sdk/client-verifiedpermissions';
import {
    isAuthorized,
    runBatchAuthorization,
} from '../services/avp/AmazonVerifiedPermissionsService.ts';
import {fetchAuthSession} from "aws-amplify/auth";

export const useVerifiedPermissions = () => {

    const authorizeAction = async (action: string, resource?: { entityType: string, entityId: string }) => {
        if (typeof action === 'undefined') {
            throw new Error('action is required parameter');
        }

        const {
            idToken
        } = (await fetchAuthSession()).tokens ?? {};

        if (typeof idToken === 'undefined') {
            throw new Error('currentSession is required parameter');
        }


        const input = { // IsAuthorizedWithTokenInput
            policyStoreId: config.permissions.policyStoreId,  // required
            identityToken: idToken.toString(),
            action: { // ActionIdentifier
                actionType: 'Action', // required
                actionId: `${action}`, // required
            },
        } as IsAuthorizedWithTokenCommandInput;

        if (resource) {
            input.resource = resource;
        }

        return await isAuthorized(input);
    };


    const batchGetActionAuthorizations = async (actions: string[]) => {
        if (typeof actions === 'undefined') {
            throw new Error('actions is required parameter');
        }

        const actionsSet = new Set(actions);

        const {
            idToken
        } = (await fetchAuthSession()).tokens ?? {};

        if (typeof idToken === 'undefined') {
            throw new Error('currentSession is required parameter');
        }


        const requests = [] as BatchIsAuthorizedWithTokenInputItem[];
        actionsSet.forEach((action) => {
            requests.push({
                              action: { // ActionIdentifier
                                  actionType: 'Action', // required
                                  actionId: `${action}`, // required
                              },
                          });
        });


        const input = { // IsAuthorizedWithTokenInput
            policyStoreId: config.permissions.policyStoreId,  // required
            identityToken: idToken.toString(),
            requests: requests,
        } as BatchIsAuthorizedWithTokenCommandInput;


        return await runBatchAuthorization(input);

    };


    return {
        authorizeAction,
        batchGetActionAuthorizations,
        ...useContext(VerifiedPermissionsContext),
    };
};