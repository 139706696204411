import {
    Center,
    Paper,
    Table,
    Text,
    Title
} from '@mantine/core';

import {
    AiOutlineCheck,
    AiOutlineClose
} from "react-icons/ai";
import {ServicePlan} from "../types.ts";
import {FlexiButton} from "@flexinet/ui-components";
import config from "../../../config";


// plan tile component
export const ServicePlanComponent = ({
                                         plan
                                     }: {
    plan: ServicePlan
}) => {
    return (
        <Paper shadow="xl" p="xl" withBorder>
            <Center>
                <Title>{plan.name}</Title>
            </Center>

            <Center style={{marginTop: 'var(--mantine-spacing-lg)'}}>
                <Title order={2} style={{color: 'var(--mantine-color-dark-3)'}}>${plan.baseFee}</Title>
            </Center>

            <Center>
                <Title order={2} style={{color: 'var(--mantine-color-dark-3)'}}>minimum / month</Title>
            </Center>

            <Center style={{marginTop: 'var(--mantine-spacing-lg)'}}>
                <Title order={2} style={{color: 'var(--mantine-color-dark-3)'}}>{plan.included}</Title>
            </Center>

            <Center>
                <Title order={2} style={{color: 'var(--mantine-color-dark-3)'}}>bot access included </Title>
            </Center>

            <Center style={{marginTop: 'var(--mantine-spacing-lg)'}}>
                <Title order={2} style={{color: 'var(--mantine-color-dark-3)'}}>${plan.price}</Title>
            </Center>

            <Center>
                <Title order={2} style={{color: 'var(--mantine-color-dark-3)'}}>bot access / month</Title>
            </Center>


            <Center style={{marginTop: 'var(--mantine-spacing-lg)'}}>
                <Table striped={true}>{plan.features.map(feature =>
                                                             <Table.Tr>
                                                                 <Table.Td>{feature.supported ?
                                                                     <AiOutlineCheck color={'green'}/> :
                                                                     <AiOutlineClose color={'red'}/>}
                                                                 </Table.Td>
                                                                 <Table.Td>{feature.title}</Table.Td>
                                                             </Table.Tr>)}
                </Table>
            </Center>

            <Center style={{marginTop: 'var(--mantine-spacing-lg)'}}>
                {plan.freeTrial ? <Title order={4}>Free 30 days trial</Title> : <Title order={4}>Free plan</Title>}
            </Center>

            <Center>
                <Text>No credit card required</Text>
            </Center>

            <Center style={{marginTop: 'var(--mantine-spacing-xl)'}}>
                <FlexiButton
                    size={'md'}
                    onClick={() => window.location.assign(config.platform.portal + '/sign-up?application=flexi-bot&plan=' + plan.id
                    )}><Text tt={'uppercase'}>Start Your Free Trial</Text></FlexiButton>

            </Center>

        </Paper>
    );

}
