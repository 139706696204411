import {DashboardWidgetBuilder} from "./DashboardWidgetBuilder.tsx";
import {
    WidgetDataPoint,
    WidgetType
} from "../../types.ts";
import {
    Center,
    Stack,
    Text,
    Title
} from "@mantine/core";
import {FlexiButton} from "@flexinet/ui-components";
import {RiMessengerFill} from "react-icons/ri";

export const BotsWidget = () => {


    const widget: WidgetType = {
        title: 'Flexi eBooks Bot',
        dataPoints: [] as WidgetDataPoint[],
        content: <Center py={100}>
            <Stack>
                <Title order={3}><Text>Discover books, authors and events</Text></Title>
                <FlexiButton
                    leftSection={<RiMessengerFill size={'2.5em'}/>}
                    size="xl"
                    onClick={() => window.open("https://m.me/flexiebooks")}
                >
                    <Title order={2}>Launch Now</Title>
                </FlexiButton>
            </Stack>
        </Center>,
        metrics: [],
        actionItems: [],
    };

    return <DashboardWidgetBuilder widget={widget}/>;
};
