import {
    Group,
    Text
} from '@mantine/core';
import {FlexiButton} from "@flexinet/ui-components";
import {useNavigate} from "react-router-dom";

export const CallToActionComponent = () => {

    const navigate = useNavigate()
    return (
        <Group>
            <FlexiButton
                size="lg"
                onClick={() => navigate('/pricing')}
            >
                <Text tt={'uppercase'}>Get started</Text>
            </FlexiButton>
        </Group>
    );
};
