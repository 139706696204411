type ConfigShape = {
    [key: string]: {
        endpoint: string,
        region: string,
        apiKey?: string,
    }
}

const apiEndpoints = {} as ConfigShape;

export const config = apiEndpoints;
