import {AiOutlineArrowDown} from 'react-icons/ai';
import {TbGauge} from "react-icons/tb";
import {DashboardWidgetBuilder} from "./DashboardWidgetBuilder.tsx";
import {WidgetType} from "../../types.ts";

export const HealthWidget = () => {
    const widget: WidgetType = {
        title: 'Platform Health',
        dataPoints: [
            {
                value: 10,
                label: 'Operational',
                color: 'green'
            },
            {
                value: 2,
                label: 'Impared',
                color: 'orange'
            },
            {
                value: 1,
                label: ' Down',
                color: 'red'
            },
        ],
        metrics: [
            {
                value: 99.96,
                unit: '%',
                label: 'Uptime',
                color: 'black',
                insight: {
                    icon: <AiOutlineArrowDown color="red"/>,
                    label: 'decrease of 0.01% from last month',
                    color: 'black',
                },
                visible: true
            },
        ],
        actionItems: [
            {
                label: 'Report Service Issue',
                description: 'Use system to report issue',
                icon: <TbGauge size={16} stroke={'1.5'}/>,
                visible: true,
            },
            {
                label: 'Subscribe To Updates',
                description: 'Use our alerting system to receive updates',
                icon: <TbGauge size={16} stroke={'1.5'}/>,
                visible: true,
            },
        ],
    };

    return <DashboardWidgetBuilder widget={widget}/>;
};
