import { createContext } from 'react';


type VerifiedPermissionsContextType = {
    authorizing: boolean;
    allowedActions: string[],
}

export const VerifiedPermissionsContext = createContext<VerifiedPermissionsContextType>({
                                                                                            authorizing: false,
                                                                                            allowedActions: [],
                                                                                        });


