import {
    Outlet,
    useNavigate
} from 'react-router-dom';


import {
    FlexiSignInButton,
    PlatformAppShell,
    PlatformFooter,
    PlatformHeader,
    PlatformHeaderNavigationMenu,
    PlatformHeaderToolbar,
    PlatformLoader,
} from '@flexinet/ui-components';
import {Suspense} from "react";
import {PortalNavbar} from "./menu-items/pages/PortalNavbar.tsx";
import {useUserAttributes} from "../hooks/useUserAttributes.ts";
import {AiOutlineLogout} from "react-icons/ai";
import {config} from "../config";
import {signInWithRedirect} from 'aws-amplify/auth';
import {useAuth} from "../hooks/useAuth.ts";


export const AppLayout = () => {
    const navigate = useNavigate()

    const {
        givenName,
        familyName,
        email
    } = useUserAttributes()

    const {user} = useAuth()

    let userInfo = undefined
    if (givenName) {
        userInfo = {
            name: [givenName, familyName].join(' '),
            email: email
        }
    }


    const additionalMenuItems = [{
        title: 'Sign Out',
        link: '/sign-out',
        onClick: () => navigate('/sign-out'),
        icon: <AiOutlineLogout/>
    }]


    const menuItems = [{
        title: 'Home',
        onClick: () => navigate('/')
    }, {
        title: 'Products',
        onClick: () => navigate('/products')
    }, {
        title: 'Pricing',
        onClick: () => navigate('/pricing')
    }, {
        title: 'About',
        onClick: () => navigate('/about')
    }] as { title: string, onClick: () => void }[]


    if (!user) {

        return <PlatformAppShell
            platformHeader={<PlatformHeader
                navigationMenu={<PlatformHeaderNavigationMenu menuItems={menuItems}/>}
                toolbar={<PlatformHeaderToolbar additionalToolbarItems={[
                    <FlexiSignInButton
                        onClick={() => {
                            signInWithRedirect()
                        }}>Sign in</FlexiSignInButton>,
                ]}/>}/>}
            platformFooter={<PlatformFooter/>}
        >
            <Suspense fallback={<PlatformLoader/>}>
                <Outlet/>
            </Suspense>
        </PlatformAppShell>

    }

    return (
        <PlatformAppShell
            platformNavbar={user ? <PortalNavbar/> : <div/>}
            platformHeader={
                <PlatformHeader
                    toolbar={<PlatformHeaderToolbar
                        user={userInfo ? userInfo : undefined}
                        portalUrl={config.platform.portal}
                        additionalMenuItems={additionalMenuItems}/>}/>
            }
            platformFooter={<PlatformFooter/>}
        >

            <Suspense fallback={<PlatformLoader/>}>
                <Outlet/>
            </Suspense>

        </PlatformAppShell>
    );


};
