import {
    Box,
    Center,
    Container,
    Grid,
    Paper,
    Stack,
    Text,
    Title
} from '@mantine/core';
import {CallToActionComponent} from "./CallToActionComponent.tsx";
import {AiOutlineBook} from "react-icons/ai";
import {ReactNode} from "react";


// product tile component
export const ProductTile = ({
                                product
                            }: {
    product: { title: string, description: string, icon: ReactNode, position: string }
}) => {

    switch (product.position) {
        case 'left':
            return (
                <Paper shadow="xl" p="xl" withBorder>
                    <Grid>
                        <Grid.Col span={4} style={{
                            textAlign: 'center',
                            marginTop: '2em'
                        }}>
                            {product.icon}
                        </Grid.Col>

                        <Grid.Col span={8}>
                            <Box style={{marginBottom: 'var(--mantine-spacing-lg)'}}>
                                <Title order={3} style={{color: 'var(--mantine-color-dark-7)'}}>{product.title}</Title>
                            </Box>
                            <Box>
                                <Text>{product.description}</Text>
                            </Box>
                        </Grid.Col>

                    </Grid>
                </Paper>
            )
        case 'right':
            return (
                <Paper shadow="xl" p="xl" withBorder>
                    <Grid>
                        <Grid.Col span={8}>
                            <Box style={{marginBottom: 'var(--mantine-spacing-lg)'}}>
                                <Title order={3} style={{color: 'var(--mantine-color-dark-6)'}}>{product.title}</Title>
                            </Box>
                            <Box>
                                <Text>{product.description}</Text>
                            </Box>
                        </Grid.Col>
                        <Grid.Col span={4} style={{
                            textAlign: 'center',
                            marginTop: '4em'
                        }}>
                            {product.icon}
                        </Grid.Col>
                    </Grid>
                </Paper>
            )
    }

}

export const ProductsComponent = () => {

    // array of service plans
    const products = [
        {
            title: "Access to Messenger Bot",
            description: "Search and find your favorite authors and books in an interactive and fun way. Browser by genre and category",
            icon: <AiOutlineBook size={'9em'} color={'var(--mantine-color-flexinet-0)'}/>,
            position: 'left'
        },
    ];

    return (
        <Container>
            <Stack>
                <Center>
                    <Title><Text style={{
                        marginTop: 'var(--mantine-spacing-xl)',
                        color: 'var(--mantine-color-dark-3)'
                    }}>Flexi Bot Products</Text></Title>
                </Center>
                <Stack style={{marginTop: 'var(--mantine-spacing-xl)'}}>
                    {products.map(product => {
                        return <ProductTile product={product}/>
                    })}
                </Stack>
                <Center style={{marginTop: 'var(--mantine-spacing-xl)'}}>
                    <CallToActionComponent/>
                </Center>
            </Stack>
        </Container>
    );
};
