import {Navigate} from "react-router-dom";
import {AboutComponent} from "../components/AboutComponent.tsx";
import {useAuth} from "../../../hooks/useAuth.ts";

export const AboutPage = () => {

    const {user} = useAuth();

    if (user) {
        return <Navigate to={'/dashboard'}/>
    } else {
        return <AboutComponent/>
    }


};
