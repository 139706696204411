// array of service plans
import {ServicePlan} from "../types.ts";


export const plans = [
    {
        id: "books-reader",
        name: "Books Reader",
        baseFee: "0",
        included: "1",
        price: "0",
        features: [
            {
                title: "Access to messenger bot",
                supported: true
            },
            {
                title: "1 User seat included.",
                supported: true
            },
            {
                title: "Support",
                supported: false
            },
        ],
        freeTrial: false,
        current: false
    },

] as ServicePlan[];

