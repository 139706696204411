import {Navigate} from "react-router-dom";
import {ProductsComponent} from "../components/ProductsComponent.tsx";
import {useAuth} from "../../../hooks/useAuth.ts";

export const ProductsPage = () => {

    const {user} = useAuth();

    if (user) {
        return <Navigate to={'/dashboard'}/>;
    } else {
        return <ProductsComponent/>
    }


};
