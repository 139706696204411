import platform from "./platform";
import permissions from "./permissions.ts";

export const config = {
    appTitle: "Flexi Network",
    appSubTitle: "Flexi Network",
    appLogoUrl: 'https://s3.amazonaws.com/cdn.flexi.network/flexi.network/images/logo/logo.png',
    platform: platform,
    permissions
}

export default config;
