import {
    Anchor,
    AspectRatio,
    Card,
    Center,
    Group,
    Paper,
    Stack,
    Text,
    Title,
} from "@mantine/core";
import {CallToActionComponent} from "../components/CallToActionComponent";

const TopContent = () => {
    return (
        <Stack gap="xl">
            <Stack>
                <Center>
                    <Title order={1}>Flexi Bot</Title>
                </Center>

                <Center>
                    <Title order={3}>Discover your favorite books and authors</Title>
                </Center>
            </Stack>

            <Center>
                <Card style={{width: "100%"}}>
                    <Card.Section>
                        <AspectRatio ratio={16 / 9}>
                            <iframe
                                height="400px"
                                src="https://www.youtube.com/embed/dyRX36eYMSQ"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />
                        </AspectRatio>
                    </Card.Section>
                </Card>
            </Center>


        </Stack>
    );
};

const FeaturesComponent = () => {
    return (
        <Stack mt="xl" gap={'xl'}>
            <Paper shadow={'sm'} p={"xl"}>
                <Title order={3}>Discover New Boks</Title>
                <Text>Fetch by author name or title</Text>
            </Paper>

            <Paper shadow={'sm'} p={"xl"}>
                <Title order={3}>Search Boks</Title>
                <Text>Fast book search</Text>
            </Paper>

            <Paper shadow={'sm'} p={"xl"}>
                <Title order={3}>Organize</Title>
                <Text>Add book to your bookshelves</Text>
            </Paper>
        </Stack>
    );
};

const HomePageFooter = () => {
    return (
        <Stack gap={'xl'}>
            <Title order={4}>Resources</Title>
            <Group grow>
                <Paper shadow={'sm'} p={"lg"}>
                    <Anchor href="https://www.facebook.com/flexiebooks" target="_blank">
                        Flexi eBooks Facebook
                    </Anchor>
                </Paper>
                <Paper shadow={'sm'} p={"lg"}>
                    <Anchor href="https://twitter.com/flexiebooks" target="_blank">
                        Flexi eBooks Twitter
                    </Anchor>
                </Paper>
            </Group>
        </Stack>
    );
};


export const MarketingComponent = () => {
    return (
        <Stack style={{
            width: "50%",
            marginLeft: "auto",
            marginRight: "auto"
        }} gap={"xl"} p={"xl"}>
            <TopContent/>
            <FeaturesComponent/>
            <HomePageFooter/>

            <Center>
                <CallToActionComponent/>
            </Center>

        </Stack>
    )

}