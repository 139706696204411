import {
    Box,
    Grid,
} from '@mantine/core';

import {ProfileWidget} from './dashboard/ProfileWidget.tsx';
import {HealthWidget} from './dashboard/HealthWidget.tsx';
import {SupportWidget} from './dashboard/SupportWidget.tsx';
import {ReactNode} from "react";
import {BotsWidget} from "./dashboard/BotsWidget.tsx";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";

export const PortalDashboard = () => {

    const {allowedActions} = useVerifiedPermissions()

    const columns = {
        leftColumn: [],
        rightColumn: []
    } as { leftColumn: ReactNode[], rightColumn: ReactNode[] }

    if (allowedActions.includes('GetBot')) {
        columns.leftColumn.push(<BotsWidget/>)
    }

    if (allowedActions.includes('GetUserProfile')) {
        columns.leftColumn.push(<ProfileWidget/>)
    }


    if (allowedActions.includes('GetPlatformHealth')) {
        columns.rightColumn.push(<HealthWidget/>)
    }

    if (allowedActions.includes('GetSupport')) {
        columns.rightColumn.push(<SupportWidget/>)
    }


    return (
        <Grid>
            <Grid.Col span={1}/>
            <Grid.Col span={4}>
                {columns.leftColumn.map((item, index) => <Box key={index}>{item}</Box>)}
            </Grid.Col>

            <Grid.Col span={6}>
                {columns.rightColumn.map((item, index) => <Box key={index}>{item}</Box>)}
            </Grid.Col>
            <Grid.Col span={1}/>
        </Grid>
    );
};
